import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"
import {ArrowPrevCircleIcon, ArrowNextCircleIcon } from "../Icons"

const NavPagination = styled.nav`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom:30px;

`
const PaginationList = styled.ul`
    display: flex;
    list-style: none;
    margin:0;
    padding:0;
    align-items: center;
`
const PageLink = styled(Link)`
    position: relative;
    display: block;
    font-family: 'Rajdhani',sans-serif;
    color: #6A6A6A;
    font-weight: 700;
    font-size:20px;
    line-height:26px;
    .icon{
        svg{
            fill:#CFCFCF;
        }
    }
    &:hover{
        color:#211F28;
        .icon{
            svg{
                fill:#211F28;
            }
        }
    }    
`

const PaginationItem = styled.li`
    margin: 0 15px;
    .icon, .text{
        display:inline-block;
        vertical-align: middle;
    }
    &.disabled {
        .icon{
            svg{
                fill:#CFCFCF;
            }
           
        }
        .text{
            color:#6A6A6A;
        }
    }
    .icon{       
        + .text{
            margin-left:15px;
        }
    }
    .text{
        + .icon{
            margin-left:15px;
        }
    }

    &.active {
       a{
        color:#211F28;
       }
    }
`
const Pagination = ({intl, data}) => {
	return(
        <NavPagination className="pagination-nav">
            <PaginationList>
                <PaginationItem className="prev">
                    {data.prev && (
                        <PageLink to={`/${intl.locale}${data.prev.slug}`}>
                            <span className="icon"><ArrowPrevCircleIcon/></span>
                            <span className="text">{intl.formatMessage({ id: "Prev" })}</span>
                        </PageLink>
                    )}
                </PaginationItem>
                <PaginationItem className="page-number"><PageLink to='/'>01</PageLink></PaginationItem>
                <PaginationItem className="page-number active"><PageLink to='/'>02</PageLink></PaginationItem>
                <PaginationItem className="page-number"><PageLink to='/'>03.....</PageLink></PaginationItem>
                <PaginationItem  className="next">
                {data.next && (
                    <PageLink to={`/${intl.locale}${data.next.slug}`}>
                        <span className="text">{intl.formatMessage({ id: "Next" })}</span>
                        <span className="icon"><ArrowNextCircleIcon/></span>
                    </PageLink>
                )}
                </PaginationItem>
            </PaginationList>
        </NavPagination>
	)	
}

export default Pagination

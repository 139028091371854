import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { v4 } from "uuid"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent } from "../components/Section"
import { BulletIcon } from "../components/Icons"
import BreakpointUp from "../components/Media/BreakpointUp"
import RecentArticleCard from "../components/RecentArticleCard"
import Pagination from "../components/Pagination"
import SocialNetwork from "../components/SocialNetwork"

const GridRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  ${BreakpointUp.lg`
        margin-right: -30px;
        margin-left: -30px;
    `}
`
const GridLeftColumn = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.md`	    
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    `}
  ${BreakpointUp.lg`	     
        padding:0 30px;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    `}
`
const GridRightColumn = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.md`
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    `}
  ${BreakpointUp.lg`	               
        padding:0 30px;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    `}
    & h2 {
    margin-bottom: 5px;
  }
`

const CardSidebarGroup = styled.div`
  padding: 0;
`
const CardSidebar = styled.div`
  position: relative;
`
const CardSidebarBody = styled.div`
  padding: 30px 0;
`
const CardSidebarHeader = styled.div`
  background-image: linear-gradient(to right, #707070 50%, #fff 0%);
  background-position: bottom center;
  background-size: 9px 1px;
  background-repeat: repeat-x;
  width: 100%;
  padding-bottom: 20px;
  ${BreakpointUp.sm`
        padding-bottom: 30px;
    `}
`
const CardSidebarTitle = styled.h2`
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 0;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 26px;
  }
  @media (min-width: 992px) {
    font-size: 26px;
    line-height: 30px;
  }
`
const ListGroupFlush = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
  margin: 0;
  a {
    color: #6a6a6a;
    &:hover {
      color: #211f28;
    }
  }
`
const ListItemFlush = styled.div`
  padding-bottom: 20px;
  ${BreakpointUp.sm`
        padding-bottom:30px;
    `}
`

const ListGroupThumb = styled.div`
  margin: 0;
`
const ListItemThumb = styled.div`
  margin: 0;
`

const PostedDate = styled.div`
  position: absolute;
  text-align: center;
  background-color: #666;
  color: #fff;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  right: auto;
  top: auto;
  padding: 15px 5px;
  font-size: 20px;
  bottom: 0;
  left: 0;
  line-height: 30px;
  ${BreakpointUp.lg`        
        bottom: -15px;
        left: -15px;
    `}
  strong {
    display: block;
    font-size: 46px;
    line-height: 36px;
    font-family: "Rajdhani", sans-serif;
    font-weight: 700;
  }
`

const SharePost = styled.div`
  margin-top: 40px;
  padding: 20px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:after,
  &:before {
    content: "";
    background-repeat: repeat-x;
    width: 100%;
    position: absolute;
    height: 1px;
  }
  &:after {
    background-image: linear-gradient(to right, #707070 50%, #fff 0%);
    background-position: top center;
    background-size: 9px 1px;
    top: 0;
  }
  &:before {
    background-image: linear-gradient(to right, #707070 50%, #fff 0%);
    background-position: bottom center;
    background-size: 9px 1px;
    bottom: 0;
  }
  .social {
    flex-direction: inherit;
    height: auto;
    li {
      margin: 0 10px;
      a {
        svg {
          fill: #6a6a6a;
        }
        &:hover {
          svg {
            fill: #000;
          }
        }
      }
    }
  }
  .pagination-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    .page-number {
      display: none;
    }
  }
`

function ArticlesDetailPage({ data, pageContext, intl }) {
  const pageData = data.contentfulArticle
  const articles = data.allContentfulArticle.edges
  const categories = data.allContentfulArticleCategory.edges

  return (
    <Layout>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription} />
      <BreadCrumb>
        <div className="container">
          <Link to={`/${intl.locale}`}>
            {intl.formatMessage({ id: "Home" })} <BulletIcon />
          </Link>
          <Link to={`/${intl.locale}/about-us`}>
            {intl.formatMessage({ id: "About Us" })} <BulletIcon />
          </Link>
          <Link to={`/${intl.locale}/blog`}>
            {intl.formatMessage({ id: "Blog" })} <BulletIcon />
          </Link>
          <span>{pageData.title}</span>
        </div>
      </BreadCrumb>
      <Section pt="230px" pb="90px" xpt="70px" xpb="60px" bgColor="#F9F9F9">
        <div className="container">
          <GridRow>
            <GridLeftColumn>
              <MarkdownContent>
                <figure>
                  <PostedDate className="post-date">
                    <strong>{pageData.postDate.substring(0, 2)}</strong>
                    {pageData.postDate.substring(2)}
                  </PostedDate>
                  <Img
                    fluid={pageData.image.fluid}
                    alt={pageData.image.description}
                  />
                </figure>
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.content.childMarkdownRemark.html,
                  }}
                />
              </MarkdownContent>
              <SharePost>
                <SocialNetwork />
                <Pagination data={pageContext} intl={intl} />
              </SharePost>
            </GridLeftColumn>
            <GridRightColumn>
              <CardSidebarGroup>
                <CardSidebar>
                  <CardSidebarHeader>
                    <CardSidebarTitle>
                      {intl.formatMessage({ id: "Categories" })}
                    </CardSidebarTitle>
                  </CardSidebarHeader>
                  <CardSidebarBody>
                    <ListGroupFlush>
                      {categories.map(item => {
                        let flag = 0
                        articles.forEach(article => {
                          if (item.node.name === article.node.category.name) {
                            flag = flag + 1
                          }
                        })
                        return (
                          <ListItemFlush>
                            <Link to={`/${intl.locale}/blog${item.node.slug}`}>
                              - {item.node.name} ({flag})
                            </Link>
                          </ListItemFlush>
                        )
                      })}
                    </ListGroupFlush>
                  </CardSidebarBody>
                </CardSidebar>
                <CardSidebar>
                  <CardSidebarHeader>
                    <CardSidebarTitle>
                      {intl.formatMessage({ id: "Recent Posts" })}
                    </CardSidebarTitle>
                  </CardSidebarHeader>
                  <CardSidebarBody>
                    <ListGroupThumb>
                      {articles.map((item, i) => {
                        if (i < 4) {
                          return (
                            <ListItemThumb key={v4()}>
                              <RecentArticleCard data={item.node} intl={intl} />
                            </ListItemThumb>
                          )
                        }
                        return true
                      })}
                    </ListGroupThumb>
                  </CardSidebarBody>
                </CardSidebar>
              </CardSidebarGroup>
            </GridRightColumn>
          </GridRow>
        </div>
      </Section>
    </Layout>
  )
}

export default injectIntl(ArticlesDetailPage)

export const query = graphql`
  query BlogQuery($slug: String, $locale: String) {
    contentfulArticle(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      node_locale
      metaTitle
      metaDescription
      slug
      title
      postDate(formatString: "D MMM YYYY")
      category {
        name
      }
      image {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
        description
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulArticle(
      sort: { order: DESC, fields: postDate }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          node_locale
          title
          slug
          postDate(formatString: "D MMM YYYY")
          category {
            name
          }
          image {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
            description
          }
          content {
            childMarkdownRemark {
              excerpt(pruneLength: 270)
            }
          }
        }
      }
    }
    allContentfulArticleCategory(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`
